import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import SEO from '../../components/SEO';
import { MainLayout } from '../../components/Layout';
import PaymentMethods from '../../components/Pricing/PaymentMethods';
import * as paymentService from '../../apis/payment';
import { isBrowser } from '../../utils/auth';

/**
 * @return {null}
 */
function Page({ location }) {
  const [selectedPaymentMethod, setPaymentMethod] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);

  useEffect(() => {
    paymentService
      .getPaymentMethods()
      .then(({ data }) => {
        setPaymentMethods(data);
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err));

    if (isBrowser() && window.innerWidth > 768) {
      navigate('/pricing/');
    }
  }, []);

  if (!location.state) {
    if (isBrowser()) navigate('/pricing/');
    return null;
  }

  return (
    <MainLayout>
      <SEO title="Pricing" keywords={[`dolonline`, `dolenglish`, `pricing`, 'ielts', 'gmat', 'gre']} />
      <PaymentMethods
        selectedPaymentMethod={selectedPaymentMethod}
        paymentMethods={paymentMethods}
        onSelectMethod={pm => {
          setPaymentMethod(pm);
        }}
        onContinue={() => {
          navigate('/pricing/term-and-policy', {
            state: { selectedPaymentMethod, parsedProducts: location.state.parsedProducts },
          });
        }}
      />
    </MainLayout>
  );
}

Page.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Page;
